<template>
  <header class="w-full py-4 px-10 border-b border-white border-opacity-20">
    <div class="w-full mx-auto flex items-center justify-between">
      <Logo :color="'white'"/>
      <nav class="space-x-4 flex">
        <select v-on:change="updateSelectedStore($event)">
          <option :selected="selectedStore == null ? 'selected' : ''" value="null">All Stores</option>
          <option :selected="selectedStore == store.name ? 'selected' : ''" v-for="store in stores" :value="store.name">{{ store.name }}</option>
        </select>
      </nav>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  components: {
    Logo,
  },
  created() {
    this.$store.dispatch('stores/getStores');
  },
  methods: {
    updateSelectedStore(event) {
      this.$store.dispatch('stores/selectStore', event.target.value);
    }
  },
  computed: {
    stores() {
      return this.$store.state.stores.stores
    },
    selectedStore() {
      return this.$store.state.stores.selectedStore
    },
  }
};
</script>
