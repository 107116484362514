import {getBaseUrl} from '@/config';
import {handleResponse} from "@/helpers/api";

export const orderServices = {
    getAll,
    updateStatus
};

function getAll() {
    let token = JSON.parse(localStorage.getItem('token'));
    let selectedStore = localStorage.getItem('selectedStore');

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken,
            'Accept': 'application/json'
        }
    };

    let url = getBaseUrl() + `fulfillment/orders`;

    if (selectedStore != 'null' && selectedStore != null) {
        url = url + '?storeName=' + selectedStore;
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(orders => {
            return orders;
        });
}

function updateStatus(orderId, newStatusHandle) {
    let token = JSON.parse(localStorage.getItem('token'));

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify({newStatusHandle})
    };

    return fetch(getBaseUrl() + `fulfillment/orders/` + orderId + `/update-status`, requestOptions);
}
