import {userService} from '../services';
import router from '../router';

const user = JSON.parse(localStorage.getItem('user'));
const token = JSON.parse(localStorage.getItem('token'));

const initialState = user ? {status: {loggedIn: true}, user, token} : {status: {}, user: null, token: null};

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({dispatch, commit}, {username, password}) {
            commit('loginRequest', {username});

            userService.login(username, password).then(token => {
                    userService.getUser(token).then(
                        user => {
                            if (user.isAdmin) {
                                commit('loginSuccess', user, token);
                                router.push('/');
                            } else {
                                alert("Only Administrators can login on this module.")
                                commit('loginFailure', "");
                                router.push('/login');
                            }
                        }),
                        error => {
                            commit('loginFailure', error);
                            router.push('/login');
                        }
                },
                error => {
                    commit('loginFailure', error);
                    router.push('/login');
                }
            );
        },
        logout({commit}) {
            userService.logout();
            commit('logout');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = {loggingIn: true};
            state.user = user;
        },
        loginSuccess(state, user, token) {
            state.status = {loggedIn: true};
            state.user = user;
            state.token = token;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
            state.token = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
            state.token = null;
        }
    }
}