import {getBaseUrl} from '@/config';
import {handleResponse} from "@/helpers/api";

export const storeServices = {
    getAll,
};

function getAll() {
    let token = JSON.parse(localStorage.getItem('token'));

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        }
    };

    return fetch(getBaseUrl() + `stores`, requestOptions)
        .then(handleResponse)
        .then(stores => {
            return stores;
        });
}
