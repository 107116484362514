<template>
  <button @click="increment">
    <img src="@/assets/logo_stamp.svg" alt="Logo"/>
  </button>
</template>

<script>

export default {
  props: ["color"],
  data() {
    return {
      count: 0
    }
  },
  methods: {
    increment() {
      this.count += 1
      if (this.count % 10 === 0) {
        localStorage.setItem('env', localStorage.getItem('env') == "stage" ? 'production' : 'stage');
        this.$store.dispatch('orders/getOrders');
        alert("You are now in " +  localStorage.getItem('env') + " environment.")
      }
    }
  }
};

</script>

