<template>
  <div class="flex-1 overflow-scroll min-h-0">
    <main class="overflow-hidden flex gap-4 mx-auto w-full max-h-full p-4 relative">
      <DraggableList :title="'New Orders'" status="appNewOrder" :list="appNewOrder"/>
      <DraggableList :title="'Processing'" status="appProcessing" :list="appProcessing"/>
      <DraggableList :title="'Preparing Food'" status="appPreparing" :list="appPreparing"/>
      <DraggableList :title="'Ready For Collection'" status="appReady" :list="appReady"/>
    </main>
  </div>
</template>

<script>

import DraggableList from "@/components/DraggableList";

export default {
  name: "home",
  components: {
    DraggableList
  },
  created() {
    this.$store.dispatch('orders/getOrders')
    setInterval(this.updateOrders, 10000);
  },
  methods: {
    updateOrders() {
      this.$store.dispatch('orders/updateOrders')
    },
    sorter(a, b) {
      return a.orderCollectionTimeFulfillment.localeCompare(b.orderCollectionTimeFulfillment);
    }
  },
  computed: {
    appNewOrder() {
      return this.$store.state.orders.orders.filter(order => order.orderStatus == "App New Order").sort(this.sorter)
    },
    appProcessing() {
      return this.$store.state.orders.orders.filter(order => order.orderStatus == "App Processing").sort(this.sorter)
    },
    appPreparing() {
      return this.$store.state.orders.orders.filter(order => order.orderStatus == "App Preparing").sort(this.sorter)
    },
    appReady() {
      return this.$store.state.orders.orders.filter(order => order.orderStatus == "App Ready").sort(this.sorter)
    },
  }
};
</script>
