import {storeServices, userService} from '../services';

const selectedStore = localStorage.getItem('selectedStore');

const initialState = {
    selectedStore: selectedStore ? selectedStore : null,
    stores: []
};

export const stores = {
    namespaced: true,
    state: initialState,
    actions: {
        getStores({dispatch, commit}) {
            storeServices.getAll()
                .then(
                    stores => {
                        commit('getStoresSuccess', stores);
                    },
                    error => {
                        commit('getStoresFailure', error);
                    }
                );
        },
        selectStore({dispatch, commit}, storeName) {
            commit('selectStore', storeName);
            dispatch('orders/getOrders', null, { root: true });
        },
    },
    mutations: {
        selectStore(state, storeName) {
            localStorage.setItem('selectedStore', storeName);
            state.selectedStore = storeName;
        },
        getStoresSuccess(state, stores) {
            state.stores = stores;
        },
        getStoresFailure(state) {
            state.stores = [];
        },
    },
}