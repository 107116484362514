import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./main.css"

Vue.config.productionTip = false

if (!localStorage.getItem('env')) {
    localStorage.setItem('env', 'stage');
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
