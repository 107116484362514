import {orderServices} from '../services';

const initialState = {
    orders: [],
    lastRefresh: new Date()
};

export const orders = {
    namespaced: true,
    state: initialState,
    actions: {
        getOrders({dispatch, commit}) {
            orderServices.getAll()
                .then(
                    orders => {
                        commit('getOrdersSuccess', orders);
                    },
                    error => {
                        commit('getOrdersFailure', error);
                    }
                );
        },
        updateOrders({dispatch, commit}) {
            if (new Date() - this.state.orders.lastRefresh > 10000) {
                orderServices.getAll()
                    .then(
                        orders => {
                            commit('getOrdersSuccess', orders);
                        },
                        error => {
                            commit('getOrdersFailure', error);
                        }
                    );
            }
        }
    },
    mutations: {
        getOrdersSuccess(state, orders) {
            state.orders = orders;
            state.lastRefresh = new Date()
        },
        getOrdersFailure(state) {
            state.orders = [];
        },
    },
}
