<template>
  <button
      class="mb-3 bg-white w-full relative shadow p-4 border-l-4 cursor-pointer transition duration-150 ease-in-out focus:outline-none"
      :class="{
      'border-purple-500': status == 'appNewOrder',
      'border-yellow-500': status == 'appProcessing',
      'border-blue-500': status == 'appPreparing',
      'border-red-500': status == 'appReady',
    }"
  >
    <span class="flex h-3 w-3 absolute -top-1.5 -right-1.5" v-if="newOrderCheck(order.dateOrdered) == true ">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
      <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
    </span>
    <div class="w-full flex justify-between items-center">
      <div v-if="order.orderCollectionTimeFulfillment"
           class="rounded text-xs px-2 py-1 text-white font-medium bg-gray-800">
        Collection: {{ collectionTime(order.orderCollectionTimeFulfillment) }}
      </div>
    </div>
    <div class="w-full flex justify-between items-center py-2 border-b border-gray-200">
      <div class="text-lg text-gray-700 font-semibold">#{{ order.id }}</div>
      <div class="text-sm text-gray-600 font-medium">{{ order.lineItems.length }}
        {{ order.lineItems.length > 1 ? 'items' : 'item' }} - £{{ formatPrice(order.total) }}
      </div>
    </div>
    <ul class="flex flex-col space-y-2 mt-4">
      <li class="space-x-2 flex items-center" v-for="item in order.lineItems">
        <div class="rounded-sm bg-gray-800 text-white font-regular text-xs px-1.5 py-0.5">{{ item.qty }}</div>
        <div class="text-sm text-gray-600">
          <span class="flex flex-col text-left">
              {{ item.description }}
          </span>
        </div>
      </li>
    </ul>

    <ul class="flex flex-col space-y-2 mt-4 list-disc list-inside text-left">
      <li class="text-sm text-gray-600" v-for="itemOption in order.appItemOptions">
        {{itemOption.name}}: {{itemOption.description}}
      </li>
    </ul>

    <div class="flex mt-4 p-4 text-white bg-green-500 rounded" v-if="status === 'appReady'">
      <svg class="w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"></path>
      </svg>
      <div class="flex-1 text-sm font-medium text-center justify-center">
        Order Collected
      </div>
    </div>
  </button>
</template>

<script>

export default {
  props: ['order', 'status'],
  computed: {},
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    collectionTime(value) {
      return value;
    },
    newOrderCheck(value) {
      let currentDateTime = new Date();
      let collectionTime = new Date(value);

      let currentTimeConverted = currentDateTime.toLocaleTimeString();
      let collectionTimeConverted = collectionTime.toLocaleTimeString();

      if (currentTimeConverted > collectionTimeConverted) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
