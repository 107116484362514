<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">

    <div class="max-w-md w-full space-y-8">
      <div class="flex flex-col items-center">
        <Logo :color="'black'"/>
        <h2 class="mt-6 text-center text-2xl font-bold text-gray-900">
          Enter Your PIN
        </h2>
        <span class="text-red-500 mt-5" v-if="pinIncorrect">Your pin was incorrect, please try again!</span>
      </div>
      <div class="confirmationDots flex items-center justify-center">
        <svg>
          <g>
            <circle class="pinCircle" cx="10" cy="10" r="8" :class="[(this.pinEntry.length >= 1 ? 'entered' : ''), (pinIncorrect ? 'error' : '')]"></circle>
            <circle class="pinCircle" cx="50" cy="10" r="8" :class="[(this.pinEntry.length >= 2 ? 'entered' : ''), (pinIncorrect ? 'error' : '')]"></circle>
            <circle class="pinCircle" cx="90" cy="10" r="8" :class="[(this.pinEntry.length >= 3 ? 'entered' : ''), (pinIncorrect ? 'error' : '')]"></circle>
            <circle class="pinCircle" cx="130" cy="10" r="8" :class="[(this.pinEntry.length >= 4 ? 'entered' : ''), (pinIncorrect ? 'error' : '')]"></circle>
          </g>
        </svg>
      </div>
      
      <div class="keypad">
        <div class="keypad--row flex justify-center items-center space-x-5 mb-5">
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('1')">1</div>
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('2')">2</div>
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('3')">3</div>
        </div>
        <div class="keypad--row flex justify-center items-center space-x-5 mb-5">
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('4')">4</div>
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('5')">5</div>
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('6')">6</div>
        </div>
        <div class="keypad--row flex justify-center items-center space-x-5 mb-5">
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('7')">7</div>
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('8')">8</div>
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('9')">9</div>
        </div>
        <div class="keypad--row flex justify-center items-center space-x-5">
          <div class="keypadButton flex justify-center items-center keyboard--button__back-arrow h-20 w-20 cursor-pointer border border-black rounded-full" @click="removeLastPin()"><i class="arrow left"></i></div>
          <div class="keypadButton flex justify-center items-center h-20 w-20 cursor-pointer border border-black rounded-full" @click="appendPinCode('0')">0</div>
          <div class="keypadButton flex justify-center items-center keyboard--button__x h-20 w-20 cursor-pointer border border-black rounded-full" @click="removeAllPins()"><span>X</span></div>
        </div>
      </div>
        
    </div>

  </div>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  name: "Home",
  components: {
    Logo,
  },
  data: () => {
    return {
      pinEntry: '',
      pinCorrect: '1150',
      pinIncorrect: false,
      username: process.env.VUE_APP_API_ADMIN_USERNAME,
      password: process.env.VUE_APP_API_ADMIN_PASSWORD,
      submitted: false
    }
  },
  created() {
    this.$store.dispatch('authentication/logout')
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    }
  },
  watch: {
    pinEntry() {
      if(this.pinEntry.length === 4) {
        if(this.pinEntry === this.pinCorrect) {
          this.handleSubmit();
        } else {
          this.pinIncorrect = true
          this.pinEntry = ''
        }
      }
    }
  },
  methods: {
    appendPinCode(value) {
      if(this.pinEntry.length - 1) {
        this.pinIncorrect = false
      }
      this.pinEntry = this.pinEntry+value
    },
    removeAllPins() {
      this.pinEntry = ''
    },
    removeLastPin() {
      this.pinEntry = this.pinEntry.slice(0, this.pinEntry.length  - 1)
    },
    handleSubmit(e) {
      this.submitted = true;
      const {username, password} = this;
      const {dispatch} = this.$store;
      if (username && password) {
        dispatch('authentication/login', {username, password});
      }
    }
  }
};
</script>
