<template>
  <div id="app">
    <div class="bg-gradient-to-r from-red-900 to-red-800 h-screen flex flex-col">
      <Header v-if="this.$route.name !== 'Login'" />
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  components: {
    Header,
  },
};
</script>
