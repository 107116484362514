import Vue from 'vue'
import Vuex from 'vuex'

import {authentication} from './authentication';
import {orders} from './orders';
import {stores} from './stores';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        authentication,
        orders,
        stores
    }
})
