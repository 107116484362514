import {getBaseUrl} from '@/config';
import {handleResponse} from "@/helpers/api";

export const userService = {
    login,
    getUser,
    logout,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username, password})
    };

    return fetch(getBaseUrl() + `user/token`, requestOptions)
        .then(handleResponse)
        .then(token => {
            if (token.accessToken) {
                localStorage.setItem('token', JSON.stringify(token));
            }
            return token;
        });
}

function getUser(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    };

    return fetch(getBaseUrl() + `user/info`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user) {
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function logout() {
    localStorage.removeItem('user');
}
