<template>
  <div class="w-1/4 card-container overflow-y-scroll rounded bg-gray-100 flex flex-col">

    <div class="bg-gray-100 sticky top-0 z-50 p-4">
      <div class="flex items-center justify-between">
        <div class="text-gray-800 text-xl font-medium">{{ title }}</div>
        <div class="text-gray-800 text-xl font-medium">{{ list.length }}</div>
      </div>
    </div>

    <draggable v-model="list" class="dragableCard pl-4 pr-4 h-full" element="span" v-bind="dragOptions" @end="onEnd">
      <transition-group name="no" class="list-group" tag="ul" :id="status">
        <Card
            v-for="order in list"
            :id="order.id"
            :order="order"
            :key="order.id"
            :status="status"
        />
      </transition-group>
    </draggable>

  </div>
</template>

<script>

import Card from '@/components/Card';
import draggable from "vuedraggable";
import {orderServices} from "@/services";

export default {
  name: "DraggableList",
  components: {
    draggable,
    Card
  },
  props: ["title", "status", "list"],
  data() {
    return {
      editable: true,
      isDragging: false,
      delayedDragging: false,
    };
  },
  methods: {
    onEnd(arg) {
      orderServices.updateStatus(arg.item.id, arg.to.id).then(() => {
        this.$store.dispatch('orders/getOrders');
      })
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: white;
}

.list-group {
  min-height: 100%;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
